import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Column } from '@tanstack/react-table';
import { useRef, useState, type SyntheticEvent } from 'react';

import { getProperties } from '../queryBuilder/propertiesUtils';
import { PropertyGroups } from '../queryBuilder/propertyGroups';
import { Icon } from '../shared/icon';

interface ColumnSelectorProps<T> {
  columns: Column<T>[];
}

export const ColumnSelector = <T,>({ columns }: ColumnSelectorProps<T>) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const groupedColumns = columns
    .filter((x) => x.getCanHide())
    .reduce<Record<string, Column<T>[]>>((acc, column) => {
      getProperties(column).forEach((prop) => {
        acc[prop.group] = acc[prop.group] || [];
        if (!acc[prop.group].includes(column)) {
          acc[prop.group].push(column);
        }
      });
      return acc;
    }, {});

  const categories = Object.keys(groupedColumns);

  return (
    <>
      <Tooltip title='Manage columns'>
        <Icon
          data-testid='column-selector'
          ref={anchorRef}
          iconClass='material-symbols-splitscreen-vertical-add-outline-rounded'
          isActive={open}
          onClick={() => setOpen(!open)}
        />
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={'left-end'}
        transition
        disablePortal
        sx={{ zIndex: 10 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper className={'shadow-lg'} sx={{ borderRadius: '16px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  sx={{
                    boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)',
                    width: '308px',
                  }}
                >
                  <CardHeader
                    className='py-[12px] px-[24px]'
                    action={
                      <Tooltip title={'Close'}>
                        <Icon
                          iconClass='material-symbols-close-rounded'
                          onClick={handleClose}
                        />
                      </Tooltip>
                    }
                    sx={{
                      padding: '12px 24px',
                    }}
                    title={
                      <Typography className='font-semibold'>
                        Manage Columns
                      </Typography>
                    }
                  />
                  <CardContent
                    sx={{
                      height: 500,
                      overflow: 'auto',
                      padding: '0',
                    }}
                  >
                    {[''].concat(Object.keys(PropertyGroups)).map((group) => {
                      const columns = groupedColumns[group];
                      if (!columns) {
                        return null;
                      }
                      const title = PropertyGroups[group]?.title;
                      return (
                        <Box key={group}>
                          {categories.length > 1 && title && (
                            <Typography
                              variant='subtitle1'
                              className='px-[24px] py-[8px] my-[12px]'
                              sx={{
                                backgroundColor:
                                  'var(--mui-palette-background-tableHeader)',
                                textTransform: 'uppercase',
                              }}
                            >
                              {title}
                            </Typography>
                          )}
                          {columns.map((column) => {
                            if (!column.getCanHide()) {
                              return null;
                            }

                            return (
                              <div key={column.id}>
                                <FormControlLabel
                                  className='w-full'
                                  control={
                                    <Checkbox
                                      sx={{ padding: '10px 20px' }}
                                      size='small'
                                      checked={column.getIsVisible()}
                                      onChange={() => {
                                        column.toggleVisibility();
                                      }}
                                    />
                                  }
                                  label={`${column.columnDef.header}`}
                                />
                              </div>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
